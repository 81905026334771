import { useStore } from '@/store';
import { computed } from 'vue';
import { camelCase } from 'lodash';

export const useAccountFeature = (feature) => {
  const store = useStore();

  const account = computed(() => store.getters['info/getAccount']);

  const isEnabled = computed(() => {
    const featureValue = account.value[camelCase(feature)];

    if (featureValue === undefined) throw new Error(`feature named as '${feature}' is not defined`);

    return !!featureValue;
  });

  return { isEnabled };
};
